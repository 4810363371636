import resume from '../assets/pdf/resume.pdf'
import photo from '../assets/pdf/photo.jpeg'

export const headerData = {
    name: 'Dhiraj Dhakal',
    title: ".net developer",
    desciption: "Software Developer -------------------------------------------------------------------------------------------                                                                                                                        ",
    image: photo,
    resumePdf: resume
}
