import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'College Management System',
        projectDesc: 'This project aims to build a management system for college and university that consolidates all its information ',
        tags: ['PHP', 'CSS', 'Material Ui', 'Html'],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: one
    },
    {
        id: 2,
        projectName: 'Neema Academy',
        projectDesc: 'The app presents an integrated solution with interactive learning programs and digital content like on-demand videos, textual content, 3D and animation-based content, and game-based learning, among others.',
        tags: ['React', 'Material UI', '.net Core', 'JQuery', 'SQL server'],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://neemaacademy.com/',
        image: two
    },
    {
        id: 3,
        projectName: 'Iskcons Wildfire',
        projectDesc: 'Iskcons Wildfire is the social media application developed for connecting the users of ISKCON community',
        tags: ['.net MVC', 'JQuery', 'SQL Server'],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://wildfire.iskcons.org/',
        image: three
    },
    // {
    //     id: 4,
    //     projectName: 'Android Patient Tracker',
    //     projectDesc: 'This project involves the development of an Android application for viewing and managing patient data.',
    //     tags: ['Flutter', 'Firebase'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: four
    // },
    // {
    //     id: 5,
    //     projectName: 'E-Commerce App',
    //     projectDesc: 'A Simple E-commerce application',
    //     tags: ['React Native', 'Firebase'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: five
    // },
    // {
    //     id: 6,
    //     projectName: 'Uber Lite',
    //     projectDesc: 'Uber clone',
    //     tags: ['Flutter'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: six
    // },
    // {
    //     id: 7,
    //     projectName: 'Stock Market App',
    //     projectDesc: 'A simple stock market API app',
    //     tags: ['React', 'Redux', 'Bootstrap'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: seven
    // },
    // {
    //     id: 8,
    //     projectName: 'Car Pooling System',
    //     projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
    //     tags: ['Flutter', 'React'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: eight
    // },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/