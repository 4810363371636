export const educationData = [
    {
        id: 1,
        institution: 'Heritage National Academy',
        course: 'SLC',
        startYear: '2061',
        endYear: '2072'
    },
    {
        id: 2,
        institution: 'Ambition Academy',
        course: '+2 Management',
        startYear: '2073',
        endYear: '2075'
    },
    {
        id: 3,
        institution: 'Ambition Academy',
        course: 'Bachelor in Computer Application',
        startYear: '2075',
        endYear: '2080'
    },
]