export const experienceData = [
    {
        id: 1,
        company: 'Braindigit IT Soutions',
        jobtitle: '.Net intern',
        startYear: 'December 2023',
        endYear: 'February 2024'
    },
    {
        id: 2,
        company: 'Braindigit IT Solutios',
        jobtitle: '.Net Developer',
        startYear: '2024',
        endYear: '-'
    },
    // {
    //     id: 3,
    //     company: 'Wonka Industries',
    //     jobtitle: 'Marketing',
    //     startYear: '2020',
    //     endYear: 'Present'
    // },
]